import * as React from 'react';
import Header from './header';

const Layout = ({ children }) => (
  <div className="p-5">
    <Header />
    <div className="pt-5 md:max-w-screen-md md:m-auto">{children}</div>
  </div>
);

export default Layout;
