import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Header = () => {
  const [hidden, setHidden] = React.useState(true);

  return (
    <header>
      <nav className="flex flex-wrap items-center justify-between">
        <Link to="/">
          <StaticImage
            src="../images/NieuwenhuisWebDevelopmentLogoComplete.png"
            alt="Nieuwenhuis Web Development B.V. Logo"
            width={100}
          />
        </Link>
        <div className="md:hidden">
          <button
            className="flex items-center px-4 py-3 border border-gray-400 text-gray-500 rounded focus:outline-none"
            onClick={() => setHidden(!hidden)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full text-center text-bold mt-5 border-t border-b border-gray-400 md:flex md:w-auto md:mt-0 md:border-none ${
            hidden ? 'hidden transition delay-150 duration-300 ease-in-out' : ''
          } `}
        >
          <div className="block px-3 py-3 md:inline-block hover:border-b-4">
            <Link to="/" activeClassName="px-1 pb-1 border-b-4 text-black">
              Home
            </Link>
          </div>
          <div className="block px-3 py-3 md:inline-block hover:border-b-4">
            <Link to="/blog" activeClassName="px-1 pb-1 border-b-4 text-black">
              Blog
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
